<template lang="pug">
v-card.d-flex.flex-column.calc(flat :class="$vuetify.breakpoint.mdAndUp ? 'calc-desktop' : 'calc'")
  v-card.text-right.d-flex.px-2(flat style="flex: 1")
    div.d-flex.flex-row-reverse(flat style="flex: 1; align-self: center")
      v-btn.ml-2(icon @click="toDetails(true)")
        v-icon mdi-barcode-scan
      v-btn(text rounded @click="toJournal()") {{ $t("смена") }}
      v-btn(text rounded @click="toDetails(false)") {{ $t("позиции") }}
      v-spacer
      v-btn.ml-1(id="paymentOptionsBtn" icon @click="showPaymentOptionsDialog()")
        v-icon mdi-alert-circle-outline
  v-card#displayCard.text-right.display-4.d-flex.px-4.calc-container(flat style="flex: 3; overflow-y: auto;")
    div.noselect.calc-display(flat style="flex: 1; align-self: center; white-space: pre-line; max-height: 100%;") {{ display }}
  div.d-flex(style="flex: 8" class="numpad")
    div.d-flex.flex-column(style="flex: 3")
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline( @click="digit('7')" :ripple="false") 7
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('8')" :ripple="false") 8
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('9')" :ripple="false") 9
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('4')" :ripple="false") 4
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('5')" :ripple="false") 5
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('6')" :ripple="false") 6
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('1')" :ripple="false") 1
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('2')" :ripple="false") 2
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('3')" :ripple="false") 3
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="plus()" :ripple="false") &plus;
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('0')" :ripple="false") 0
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="backspace()" :ripple="false")
            v-icon mdi-backspace
    div.d-flex.flex-column(style="flex: 1")
      div(style="flex: 1; display: flex;")
        v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important;" depressed color="secondary" @click="switchPaymentType('PAYMENT_CASH')" :ripple="false" :class="paymentType === 'PAYMENT_CASH' ? 'payment-btn-active' : 'payment-btn'") {{ $t('paymentType.PAYMENT_CASH_short_calc') }}
      div(style="flex: 1; display: flex;")
        v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="secondary" @click="switchPaymentType('PAYMENT_CARD')" :ripple="false" :class="paymentType === 'PAYMENT_CARD' ? 'payment-btn-active' : 'payment-btn'") {{ $t('paymentType.PAYMENT_CARD_short_calc') }}
      div(style="flex: 1; display: flex;")
        v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="secondary" @click="switchPaymentType('PAYMENT_MOBILE')" :ripple="false" :class="paymentType === 'PAYMENT_MOBILE' ? 'payment-btn-active' : 'payment-btn'") {{ $t('paymentType.PAYMENT_MOBILE_short_calc') }}
      div(style="flex: 3; display: flex;")
        v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="primary" @click="next()" :ripple="false") {{ $t("далее") }}

  v-dialog(v-model="paymentOptionsDialog" light)
    v-card(:style="paymentOptionsDialogStyle").pb-2.hint-dialog
      div.up-triangle
      v-card-title.pb-0.pt-1
        v-row(row wrap)
          v-col.pb-0(cols="10")
            .subtitle-1 {{ $t("какой_тип_оплаты_выбрать") }}
          v-col.pb-0.pt-2(cols="2")
            v-btn(icon @click.native="paymentOptionsDialog = false")
              v-icon mdi-close
      v-card-text.py-3
        .subtitle-1(v-html="paymentOptionsText")
        div.text-center
          a(@click="showChat()") {{ $t("напишите_в_службу_поддержки") }}

</template>
<script>
import fdo from '@comrun/kfdo'
import { Decimal } from 'decimal.js'
import { mapState, mapActions } from 'vuex'
import i18n from '../../i18n/index'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  name: 'Calculator',

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    paymentType: 'PAYMENT_CASH',
    stack: [''],
    index: 0,
    display: '0',
    total: 0,

    paymentOptionsDialog: false,
    paymentOptionsText: `
      <span style="color: rgba(0, 0, 0, 0.87); line-height: 18px; font-size: 15px;">
      <p class="mb-0"><strong style="color: #20B980;">${i18n.t('наличные_налич_если_оплата')}:</p>
      <ul>
        <li>${i18n.t('наличными')}</li>
        <li><strong>${i18n.t('переводом')}</li>
      </ul>
      <p class="mb-0 mt-4"><strong style="color: #20B980;">${i18n.t('карта_если_оплата')}:</p>
      <ul>
        <li>${i18n.t('банковской_картой')}</li>
        <li>${i18n.t('через_маркетплейс')}</li>
        <li>${i18n.t('бесконтактным_способом_apple_pay_google_pay_samsung_pay')}</li>
        <li>${i18n.t('в_рассрочку_или_кредит_уточните_в_вашем_банке')}</strong></li>
      </ul>
      <p class="mb-0 mt-4"><strong style="color: #20B980;">${i18n.t('мобильный_платеж_если_оплата')}:</p>
      <ul>
        <li>${i18n.t('через_qr_код')}</li>
        <li>${i18n.t('через_удаленную_оплату_выставление_счета')}</li>
      </ul>
      <p class="mb-0 mt-4">${i18n.t('при_перечислении_с_одного_расчетного_счета_на_другой_через_платежное_поручение_и_прочее_чеки_выбивать_не_нужно')}</p>
      <p class="text-center mb-0 mt-4">${i18n.t('остались_вопросы')}</p>
      </span>
    `,
    paymentOptionsDialogStyle: {
      top: '0px',
      left: '0px',
    },
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      keyListenerEnabled: state => state.tools.keyListenerEnabled,
    }),

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },
  },

  watch: {
    paymentOptionsDialog(value) {
      if (!value) {
        this.analyticsLogEvent({ eventName: 're_cash_payment_options_closed' })
      }
    },
  },

  created() {
    if (this.$isFFBMode() && localStorage.getItem('rekassa.kz-ui-defaultPaymentType') === null) {
      this.paymentType = 'PAYMENT_CARD'
    } else {
      this.paymentType = localStorage.getItem('rekassa.kz-ui-defaultPaymentType') || 'PAYMENT_CASH'
    }
  },

  mounted() {
    this.setTicketRequest(null)
    window.addEventListener('keydown', this.keyListener, false)

    const paymentOptionsHintShowed = (localStorage.getItem('rekassa.kz-payment-options-hint-showed') || 'false') === 'true'
    if (!paymentOptionsHintShowed) {
      this.showPaymentOptionsDialog()
      localStorage.setItem('rekassa.kz-payment-options-hint-showed', 'true')
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyListener, false)
  },

  methods: {
    ...mapActions({
      setTicketRequest: 'cashRegisters/setTicketRequest',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    formatThousends(x) {
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },

    keyListener(event) {
      if (this.keyListenerEnabled && (this.cashRegister.status === 'REGISTERED' || this.cashRegister.status === 'TRIAL')) {
        const reg = new RegExp('^[0-9\\.]$')
        if (reg.test(event.key)) {
          this.digit(event.key)
        } else if (event.key === 'Backspace') {
          this.backspace()
        } else if (event.key === 'Enter') {
          this.next()
        } else if (event.key === '+') {
          this.plus()
        }
      }
    },

    toDetails(openScanner) {
      try {
        localStorage.setItem('rekassa.kz-tools-openScanner', openScanner === true ? 'true' : false)
        if (this.total === 0) {
          this.$router.push('/details')
        } else {
          this.prepareTicketRequest()
          this.$router.push('/details')
        }

        if (openScanner) {
          this.analyticsLogEvent({ eventName: 're_positions_barcode_click_button' })
        } else {
          this.analyticsLogEvent({ eventName: 're_positions_click_button' })
        }
      } catch (error) {
        this.$router.push('/details')
      }
    },

    toJournal() {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}`)
      } else {
        this.$router.push(`/shifts/${this.cashRegister.id}`)
      }
      this.analyticsLogEvent({ eventName: 're_shift_click_button' })
    },

    switchPaymentType(type) {
      this.playClickSound()
      this.paymentType = type
    },

    digit(digit) {
      this.playClickSound()
      if ((this.stack[this.index] === '' && digit === '0') || (this.stack[this.index] !== '' && this.stack[this.index].length >= 9)) return
      if (this.stack[this.index] === '') {
        this.stack[this.index] = digit
      } else {
        this.stack[this.index] += digit
      }
      this.prepareDisplay()
    },

    backspace() {
      this.playClickSound()
      if (this.stack[this.index] === '' && this.index > 0) {
        this.index -= 1
        this.stack.pop()
      } else {
        this.stack[this.index] = this.stack[this.index].substring(0, this.stack[this.index].length - 1)
      }
      this.prepareDisplay()
    },

    plus() {
      this.playClickSound()
      if (this.stack[this.index] !== '' && this.stack.length <= 49) {
        this.index += 1
        this.stack[this.index] = ''
        this.prepareDisplay()
      }
    },

    prepareDisplay() {
      this.display = this.stack.reduce((all, item) => {
        if (all === '') {
          // eslint-disable-next-line no-irregular-whitespace
          return item !== '' ? `${this.formatThousends(item)}‎ ₸` : '0'
        }
        // eslint-disable-next-line no-irregular-whitespace
        return item !== '' ? `${all} + ${this.formatThousends(item)}‎ ₸` : `${all} + 0`
      }, '')
      this.total = this.stack.reduce((all, item) => (item !== '' ? new Decimal(all).plus(item).toNumber() : all), 0)
      this.$nextTick(() => {
        const el = document.getElementById('displayCard')
        el.scrollTop = el.scrollHeight
      })
    },

    next() {
      this.playClickSound()
      try {
        if (this.total === 0) {
          this.showSnackbar({ message: this.$t('введите_сумму'), timeout: 1000 })
          return
        }
        this.prepareTicketRequest()
        localStorage.setItem('rekassa.kz-ticket-prepared-from', 'CALCULATOR')
        this.$router.push('/draft')
      } catch (error) {
        // console.debug(`error: ${error}`)
      }
    },

    prepareTicketRequest() {
      const ticketRequest = new fdo.kkm.proto.TicketRequest()

      ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum.OPERATION_SELL
      ticketRequest.domain = new fdo.kkm.proto.TicketRequest.Domain({ type: this.dictionary.domainType[this.preferences.domainType].code })

      this.stack.forEach((item) => {
        if (item !== '') {
          ticketRequest.items.push(this.prepareItem(new Decimal(item).toNumber()))
        }
      })

      const payment = new fdo.kkm.proto.TicketRequest.Payment({
        type: fdo.kkm.proto.PaymentTypeEnum[this.paymentType],
        sum: this.getBillsAndCoinsMoney(this.total),
      })
      ticketRequest.payments.push(payment)

      ticketRequest.amounts = new fdo.kkm.proto.TicketRequest.Amounts({
        total: this.getBillsAndCoinsMoney(this.total),
        taken: this.getBillsAndCoinsMoney(this.paymentType === 'PAYMENT_CASH' ? this.total : 0),
        change: this.getBillsAndCoinsMoney(0),
      })

      this.setTicketRequest(ticketRequest)
    },

    prepareItem(sum) {
      const item = new fdo.kkm.proto.TicketRequest.Item({
        type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY,
        commodity: new fdo.kkm.proto.TicketRequest.Item.Commodity({
          name: this.preferences.defaultItemName,
          sectionCode: '1',
          quantity: 1000,
          price: this.getBillsAndCoinsMoney(sum),
          sum: this.getBillsAndCoinsMoney(sum),
          measureUnitCode: this.unitTypes.find((ut) => ut.value === this.preferences.defaultItemType).code,
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: 'UNIT_TYPE',
              value: this.preferences.defaultItemType,
            }),
          ],
        }),
      })

      if (this.configuration.taxMode && this.dictionary.taxType[this.preferences.defaultTaxType].rate >= 0) {
        item.commodity.taxes = [
          new fdo.kkm.proto.TicketRequest.Tax({
            taxType: 100,
            taxationType: this.dictionary.taxationType[this.configuration.taxationType].code,
            percent: new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).times(1000).toNumber(),
            sum: this.getBillsAndCoinsMoney(new Decimal(sum)
              .dividedBy(new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).add(100))
              .times(this.dictionary.taxType[this.preferences.defaultTaxType].rate)
              .toNumber()
              .toFixed(2)),
            isInTotalSum: true,
          }),
        ]
        item.commodity.taxes[0].sum.value = this.commodityTax
      }

      return item
    },

    playClickSound() {
      if (this.$isCordova()) {
        window.nativeclick.trigger()
      }
    },

    showPaymentOptionsDialog() {
      const paymentOptionsBtnElement = document.getElementById('paymentOptionsBtn')
      const rect = paymentOptionsBtnElement.getBoundingClientRect()
      this.paymentOptionsDialogStyle.top = `${rect.top + 40}px`
      this.paymentOptionsDialogStyle.left = `${rect.left + 40}px`

      this.$nextTick(() => {
        this.paymentOptionsDialog = true
      })

      this.analyticsLogEvent({ eventName: 're_cash_payment_options_showed' })
    },

    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {})
      }
      this.analyticsLogEvent({ eventName: 're_payment_options_helpcrunch_button_click' })
      this.showPaymentOptions = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.calc
  height 100%
  max-width 450px
  position relative
  top 0
  left 0
  margin 0 auto
.calc-desktop
  height calc(100% - 12px)
  max-width 600px
  position relative
  top 12px
  left 0
  margin 0 auto
.calc-display
  // font-size calc(3.5vw + 3.5vh + 2vmin)
  // line-height calc(3.5vw + 3.5vh + 2vmin)
  font-size 3.5rem
  line-height 3.5rem
  letter-spacing initial !important
  font-family "Eczar", sans-serif !important
  white-space normal !important
.numpad .v-btn
  height auto
  width 100%
.payment-btn
  color rgba(255, 255, 255, 0.3) !important
  opacity 0.5 !important
.payment-btn-active
  color #fff !important
.numpad .v-btn:active
  opacity 0.6 !important
  transition-property opacity
  transition-duration 0s

.hint-dialog
  top 0px
  left 0px
  position absolute
  width 300px
.up-triangle
  width: 0;
  height: 0;
  border-bottom: 40px solid #ffffff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -19px;
  left: -14px;
  rotate: -44deg;
</style>
